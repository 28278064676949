import pageImageDesktop from '@/assets/images/page-desktop.jpg';
import pageImageMobile from '@/assets/images/page-mobile.jpg';
import { MainImage } from '@/pages/home/index.styles';
import { kakaotalk } from '@/services';
import { useMedia } from '@/hooks';
import ImageMap from 'image-map';

const Index = () => {
  const { md } = useMedia();

  const initImageMap = () => ImageMap('img[usemap]');

  return (
    <div>
      <MainImage
        src={pageImageDesktop}
        alt=""
        useMap="#image-map-desktop"
        hidden={!md}
        draggable={false}
        onLoad={initImageMap}
      />
      <MainImage
        src={pageImageMobile}
        alt=""
        useMap="#image-map-mobile"
        hidden={md}
        draggable={false}
        onLoad={initImageMap}
      />

      {md && (
        <map name="image-map-desktop">
          <area target="" title="" alt="" href={kakaotalk} coords="862,719,1692,898" shape="rect" />
          <area target="" title="" alt="" href={kakaotalk} coords="839,2794,1710,3047" shape="rect" />
        </map>
      )}

      {!md && (
        <map name="image-map-mobile">
          <area target="" title="" alt="" href={kakaotalk} coords="347,379,653,442" shape="rect" />
          <area target="" title="" alt="" href={kakaotalk} coords="331,1893,680,1996" shape="rect" />
        </map>
      )}
    </div>
  );
};

export default Index;
